<template>
  <div>
    coming soon
  </div>
</template>

<script>
export default {
  name: "income",
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.$root.title = this.$t("MENU.income");
  },
  destroyed() {},
  methods: {}
};
</script>
